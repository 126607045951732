import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { BsHourglassSplit } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';

import { formatCurrency } from '../../function/FormatCurrency';
import { useConferenciaDeVendas } from '../../ConferenciaDeVendasContext';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { ContainerPagination } from '../../styles';
import { Totalizadores } from '../../Totalizadores';

export const DataGridComponent: React.FC = () => {
  const { searchRows, handleConferido } = useConferenciaDeVendas();

  const Paginated: React.FC = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <p className="my-2">
            {`${searchRows.length} registro(s) encontrado(s)`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: '10px',
        }}
      >
        <Col md={12} sm={12}>
          <Paper style={{ width: '100%' }}>
            <TableContainer style={{ maxHeight: '350px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Loja</TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="right">PDV</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="right">Tot. Vda</TableCell>
                    <TableCell align="right">Tot. Imp.</TableCell>
                    <TableCell align="right">Tot. Canc.</TableCell>
                    <TableCell align="center">Conferido?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchRows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell
                          style={{
                            minWidth: '100px',
                            textAlign: 'center',
                          }}
                        >
                          {row.cod_loja}
                        </TableCell>

                        <TableCell
                          style={{
                            minWidth: '140px',
                            textAlign: 'center',
                          }}
                        >
                          {row.dta_venda}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: '120px',
                            textAlign: 'right',
                          }}
                        >
                          {row.num_pdv}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: '120px',
                            textAlign: 'left',
                          }}
                        >
                          {row.des_tipo_status}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: '130px', textAlign: 'right' }}
                        >
                          {formatCurrency(row.val_total_venda)}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: '130px', textAlign: 'right' }}
                        >
                          {formatCurrency(row.val_total_imposto)}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: '130px', textAlign: 'right' }}
                        >
                          {formatCurrency(row.val_total_cancelado)}
                        </TableCell>

                        {!row.flg_conferido && row.tipo_status === 0 && (
                          <TableCell style={{ textAlign: 'center' }}>
                            <BsHourglassSplit
                              size={28}
                              style={{ color: '#72ab90' }}
                            />
                          </TableCell>
                        )}
                        {!row.flg_conferido && row.tipo_status === 1 && (
                          <TableCell
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <FaCheckCircle
                              size={28}
                              onClick={() => handleConferido(row.num_pdv)}
                              style={{ color: 'gray', cursor: 'pointer' }}
                            />
                          </TableCell>
                        )}
                        {row.flg_conferido && (
                          <TableCell style={{ textAlign: 'center' }}>
                            <FaCheckCircle
                              size={28}
                              style={{ color: 'green' }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                {searchRows.length > 0 && <Totalizadores />}

                {searchRows.length === 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={8}
                      style={{ height: '220px' }}
                    >
                      Nenhum registro encontrado...
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>

            <ContainerPagination>
              <Paginated />
            </ContainerPagination>
          </Paper>
        </Col>
      </Row>
    </>
  );
};
