import React from 'react';
import { ConferenciaDeVendasContextProvider } from './ConferenciaDeVendasContext';
import { ConferenciaDeVendasContent } from './ConferenciaDeVendasContent';

export const ConferenciaDeVendas: React.FC = () => {
  return (
    <ConferenciaDeVendasContextProvider>
      <ConferenciaDeVendasContent />
    </ConferenciaDeVendasContextProvider>
  );
};
