import React from 'react';

import DefaultLoader from '~/components/DefaultLoader';

import { Button, ContainerButtCapa } from '../../styles';
import { Row, Col } from 'react-bootstrap';
import { ClearButton } from '~/components/Buttons';

import { MdOutlineCancel } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { FaEraser } from 'react-icons/fa';
import { useConferenciaDeVendas } from '../../ConferenciaDeVendasContext';

const ContainerButtonCapa: React.FC = () => {
  const {
    handleCancel,
    resetInput,
    handleSearch,
    loaderSearch,
    disableButtonCapa,
  } = useConferenciaDeVendas();
  return (
    <ContainerButtCapa>
      <Row style={{ width: '100%' }}>
        <Col md={12} sm={12} className="colButton">
          <Button
            className="search"
            onClick={() => {
              handleSearch();
            }}
            disabled={disableButtonCapa}
            style={{
              color: '#ffffff',
              border: 'none',
              height: '100%',
              maxHeight: '42px',
              minWidth: '150px',
            }}
          >
            {loaderSearch && <DefaultLoader />}
            {!loaderSearch && (
              <>
                <IoIosSearch
                  focusable={false}
                  aria-hidden="true"
                  size={20}
                  style={{
                    marginTop: '-2px',
                    color: '#fff',
                  }}
                />
                Pesquisar
              </>
            )}
          </Button>
        </Col>
        <Col md={12} sm={12} className="colButton">
          <ClearButton
            onClick={() => handleCancel()}
            disabled={!disableButtonCapa}
            style={{
              color: '#ffffff',
              Border: 'none',
              height: '100%',
              maxHeight: '42px',
              minWidth: '150px',
            }}
          >
            <MdOutlineCancel
              focusable={false}
              aria-hidden="true"
              size={20}
              style={{
                marginTop: '-2px',
                color: '#fff',
                marginRight: '5px',
              }}
            />
            Cancelar
          </ClearButton>
        </Col>
        <Col md={12} sm={12} className="colButton2">
          <Button
            className="confirm"
            onClick={() => resetInput()}
            disabled={disableButtonCapa}
            style={{
              color: 'rgb(255, 255, 255)',
              height: '100%',
              maxHeight: '42px',
              minWidth: '150px',
            }}
          >
            <FaEraser
              focusable={false}
              aria-hidden="true"
              size={20}
              style={{
                marginTop: '-2px',
                color: '#fff',
              }}
            />
          </Button>
        </Col>
      </Row>
    </ContainerButtCapa>
  );
};

export default ContainerButtonCapa;
