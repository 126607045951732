import api from '~/services/api';
import { nanoid } from 'nanoid';
import { SearchProps } from '../../types';

export async function getSearch(
  dta_mov: string,
  num_pdv: number,
  cod_loja: number,
  flg_forcar_recalculo: boolean,
): Promise<SearchProps[]> {
  try {
    flg_forcar_recalculo = flg_forcar_recalculo || false;

    const { data } = await api.get(
      `/conferencia-venda/search/${cod_loja}/${dta_mov}/${flg_forcar_recalculo}`,
      {
        params: { num_pdv },
      },
    );

    if (data.data) {
      const search = data.data.map((value: any) => {
        value.id = nanoid();
        return value;
      });
      return search;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
