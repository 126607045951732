import api from '~/services/api';
import { PdvProps } from '../../types';

export async function getPdvs(cod_loja: number): Promise<PdvProps[]> {
  try {
    const { data } = await api.get(`/conferencia-venda/pdvs/${cod_loja}`);
    if (data.data) {
      const pdvs = data.data
        .filter((v: any) => v.flg_inativo !== true)
        .map((value: any) => {
          return value;
        });

      return pdvs;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
