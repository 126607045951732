import React from 'react';

import FooterDefault from '~/components/FooterDefault';
import { ButtonSeparator, Container, SeparatorLine, Title } from './styles';
import Loja from '~/components/Loja';
import { Row, Col } from 'react-bootstrap';
import { InputDate, InputSelect } from '~/components/NovosInputs';

import { useConferenciaDeVendas } from './ConferenciaDeVendasContext';
import ToggleDefault from '~/components/ToggleDefault';
import ContainerButtonCapa from './Component/ContainerButtonCapa';
import { DataGridComponent } from './Component/DataGridComponent';
import Separator from '~/components/Separator';

export const ConferenciaDeVendasContent: React.FC = () => {
  const {
    setValue,
    register,
    errors,
    control,
    watchLoja,
    handleGetPdv,
    pdvs,
    watchFlgForcarRecalculo,
    disableButtonCapa,
    handleConferirTodos,
    searchRows,
  } = useConferenciaDeVendas();

  return (
    <Container>
      <Title>Conferência de Vendas - SAT/NFCE</Title>
      <SeparatorLine />
      <br />
      <Row>
        <Col md={5} sm={12} style={{ marginTop: '10px' }}>
          <Loja
            onChange={(val: any) => {
              handleGetPdv();
              setValue('loja', val);
            }}
            selectedLoja={watchLoja}
            isDisabled={disableButtonCapa}
          />
        </Col>

        <Col md={2} sm={12}>
          <InputDate
            label="Data Mov:"
            placeholder=""
            name="dta_mov"
            register={register}
            disabled={disableButtonCapa}
            isError={!!errors.dta_mov}
            control={control}
            onChange={(event: any) => {
              setValue('dta_mov', event.target?.value);
            }}
          />
        </Col>
        <Col md={3} sm={12}>
          <InputSelect
            label="PDV:"
            placeholder="Selecione..."
            name="pdv"
            register={register}
            disabled={disableButtonCapa}
            isError={!!errors.pdv}
            control={control}
            options={pdvs}
            changeSelected={(selected) => {
              setValue('pdv', selected);
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <ToggleDefault
            labelText="Forçar Recálculo?"
            setChecked={watchFlgForcarRecalculo}
            disabled={disableButtonCapa}
            onSwitch={() => {
              setValue('flg_forcar_recalculo', !watchFlgForcarRecalculo);
            }}
          />
        </Col>
      </Row>
      <br />
      <ContainerButtonCapa />
      <br />
      <Separator labelText="" lineColor="#8850bf" childrenWidth="auto">
        <ButtonSeparator
          style={{
            marginTop: '-7px',
          }}
          type="button"
          disabled={searchRows.length === 0}
          onClick={() => handleConferirTodos()}
        >
          Conferir Todos
        </ButtonSeparator>
      </Separator>
      <DataGridComponent />
      <FooterDefault codTela={289} />
    </Container>
  );
};
