import api from '~/services/api';

export async function putConferido(
  cod_loja: number,
  dta_mov: string,
  num_pdv?: number,
): Promise<boolean> {
  try {
    const { data } = await api.put(`/conferencia-venda`, {
      cod_loja,
      num_pdv,
      dta_mov,
    });

    return data.success;
  } catch (error: any) {
    return false;
  }
}
