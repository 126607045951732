import React from 'react';
import { useConferenciaDeVendas } from '../ConferenciaDeVendasContext';
import { formatCurrency } from '../function/FormatCurrency';
import { TableRow, TableCell } from '@material-ui/core';

export const Totalizadores: React.FC = () => {
  const { totalVenda, totalImposto, totalCancelado } = useConferenciaDeVendas();

  return (
    <TableRow
      style={{
        position: 'sticky',
        bottom: '0px',
        backgroundColor: 'rgb(241, 241, 241)',
      }}
      key={1}
    >
      <TableCell
        style={{
          width: '100px',
          fontWeight: 700,
        }}
      />
      <TableCell
        style={{
          width: '140px',
        }}
      />
      <TableCell
        style={{
          width: '120px',
        }}
      />
      <TableCell
        style={{
          width: '140px',
        }}
      />
      <TableCell
        style={{
          width: '130px',
          textAlign: 'right',
          backgroundColor: 'rgb(229, 229, 229)',
          fontWeight: 700,
        }}
      >
        {formatCurrency(totalVenda, 2)}
      </TableCell>
      <TableCell
        style={{
          width: '130px',
          textAlign: 'right',
          backgroundColor: 'rgb(229, 229, 229)',
          fontWeight: 700,
        }}
      >
        {formatCurrency(totalImposto, 2)}
      </TableCell>
      <TableCell
        style={{
          width: '130px',
          textAlign: 'right',
          backgroundColor: 'rgb(229, 229, 229)',
          fontWeight: 700,
        }}
      >
        {formatCurrency(totalCancelado, 2)}
      </TableCell>
      <TableCell
        style={{
          width: '100px',
        }}
      />
    </TableRow>
  );
};
